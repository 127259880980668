window.addEventListener("load", function() {
  // scroll
  $(window).on("scroll", function() {
    if ($(this).scrollTop() > 50) {
      if (!$(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").addClass("navbar--scrolled");
        $(".slider").addClass("slider--scrolled");
        $(".breadcrumb").fadeOut(350);
      }
    } else {
      if ($(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").removeClass("navbar--scrolled");
        $(".slider").removeClass("slider--scrolled");
        $(".breadcrumb").fadeIn(350);
      }
    }
  });

  // menu
  const menuOpen = () => {
    $("body").css("overflow", "hidden");
    $("#menu").addClass("menu--opened");
    $(".navbar").addClass("navbar--scrolled");
    $("#hamburgerOpen").fadeOut(250);
    setTimeout(function() {
      $("#hamburgerClose").fadeIn(250);
    }, 250);
  };

  const menuClose = () => {
    $("#menu").removeClass("menu--opened");
    $("#hamburgerClose").fadeOut(250);
    setTimeout(function() {
      $("#hamburgerOpen").fadeIn(250);
    }, 250);
    setTimeout(function() {
      $("body").css("overflow", "auto");
      if ($(window).scrollTop() > 50) {
        $(".navbar").addClass("navbar--scrolled");
      } else {
        $(".navbar").removeClass("navbar--scrolled");
      }
    }, 660);
  };

  $(".hamburger").on("click", function() {
    $(this).toggleClass("hamburger--clicked");
    if ($(this).hasClass("hamburger--clicked")) {
      menuOpen();
    } else {
      menuClose();
    }
  });
});
